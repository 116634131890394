import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getData();
    setReady(true);
  }

  async function confirmPayment() {
    setLoading(true);
    await callApi("confirm-subscription-order-payment", { id }).then(response => {
      if (response.status === 1) {
        getData();
        appContext.tellMessage("Successful");
        appContext.getAllSubscriptionOrders(); //refresh orders
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }


  async function getData() {
    await callApi("get-subscription-order-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError("Could not fetch data, check your connection");
      }
    })
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        <div className="row">
          <div className="col-md-12">
            <h6 className="main-section-title">
              {data.subscriptionDuration} Days
            </h6>

            <h6
              style={{
                fontSize: "18px"
              }}
              className="font-semi-bold text-primary"
            >
              {formatMoney(data.subscriptionPrice)} TZS
            </h6>

            <span
              className="category-badge-sm bg-primary"
            >
              {data.status}
            </span>

            <hr />

            <h6
              style={{
                fontSize: "12px"
              }}
              className="font-semi-bold text-success"
            >
              REF: {data.referenceNumber || "N/A"}
            </h6>
            <h6
              style={{
                fontSize: "12px"
              }}
              className="font-semi-bold text-success"
            >
              Trans ID: {data.transactionId || "N/A"}
            </h6>

            <h6
              style={{
                fontSize: "12px"
              }}
              className="font-semi-bold text-success"
            >
              Phone: {data.payPhone || "N/A"}
            </h6>

            <h6
              style={{
                fontSize: "12px"
              }}
              className="font-semi-bold text-success"
            >
              Payment method: {data.payWith}
            </h6>

            <h6
              style={{
                fontSize: "12px"
              }}
              className="font-semi-bold text-success"
            >
              Order Date: {formatDate(data.orderDate) || "N/A"}
            </h6>


          </div>

          <div className="col-md-12 text-end mt-4 mb-4">
            {
              (data.status === 'pending') ?
                <button
                  className="btn btn-outline-primary btn-rounded"
                  onClick={() => {
                    appContext.activateDialog({
                      message: "Confirm that you have received payment for this order",
                      onConfirm: confirmPayment,
                    })
                  }}
                >
                  Confirm Payment
                </button>
                : ""
            }
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}